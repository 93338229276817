.chat-container {
  width: 900px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

.chat-box {
  height: 400px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
}

.message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 20px;
  max-width: 60%;
  word-wrap: break-word;
}

.message.user {
  background-color: #dcf8c6;
  align-self: flex-end;
  margin-left: auto;
}

.message.bot {
  background-color: #ebebeb;
  align-self: flex-start;
  margin-right: auto;
}

.message-input {
  display: flex;
}

.message-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.message-input button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.message-input button:disabled {
  background-color: #ccc;
}

.loading {
  text-align: center;
  color: #999;
}
