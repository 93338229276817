.navBrand p {
  color: #f8f9fa;
  font-size: 30px;
  padding-left: 10px;
  font-weight: 600;
  margin: 0;
}

.cont {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 40%;
}

.cont button {
  margin-right: 10px;
  font-size: 17px;
  font-weight: 600;
  background-color: white;
  border: none;
  color: #0d6efd;
}
.offcanvas-link {
  display: block;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  background-color: transparent;
  color: black;
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.cont a {
  color: white;
  text-decoration: none;
}
.cont a:hover {
  color: white;
  font-weight: 600;
  text-decoration: none;
}

.offcanvas-link:hover {
  background-color: #f8f9fa; /* A light grey for hover effect, adjust as needed */
  color: #3cd16f;
  text-decoration: none; /* This ensures that the text decoration is removed on hover */
}
